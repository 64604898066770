import { Router, ActivatedRoute} from "@angular/router";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from "firebase/app";
import { Observable, of, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { tap } from "rxjs/operators";
import { ApiValidatorService } from "app/apiValidator.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AuthService {
  private user: string;
  private userId: string;
  private userDetails: firebase.User = null;
  company: { useRoutes: boolean};

  private useRouteOptimizerSubject = new BehaviorSubject<boolean>(false);
  data$ = this.useRouteOptimizerSubject.asObservable();

  constructor(
    public _firebaseAuth: AngularFireAuth,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private apiValidatorService: ApiValidatorService,
    private translate: TranslateService
  ) {
    this.user = localStorage.getItem("username");
    this.userId = localStorage.getItem("userId");
  }

  getUser() {
    return this.user;
  }

  getUserId() {
    return this.userId;
  }

  setUseRouteOptimizerSubject(newData: boolean): void {
    this.useRouteOptimizerSubject.next(newData);
  }

  signupUser(form: any) {
    return this.http
      .post<{ username: string; token: string }>(
        `${environment.baseUrl}/signup`,
        { userData: form }
      )
      .pipe(
        tap((data) => {
          console.log("data", data);
        })
      );
  }

  signinUser(email: string, password: string) {

    return this.http.post<{username: string; token: string; id: string; company:string;}>(
        `${environment.baseUrl}/login`,
        { email, password }
      )
      .pipe(
        tap((data) => {
          this.user = data.username;
          localStorage.setItem("token", data.token);
          localStorage.setItem("username", data.username);
          localStorage.setItem("userId", data.id);
          localStorage.setItem("company", data.company);

          this.getUserAndCompanyInformation();
        })
      );
  }

  signWithUrl() {
    let token = "";
    let data = null;

    this.activatedRoute.queryParams.subscribe((params) => {
      token = params["token"];
    });

    if (token != undefined) {

      data = JSON.parse(atob(token.split(".")[1]));
      
      this.user = data.username;
      localStorage.setItem("token", token);
      localStorage.setItem("username", data.username);
      localStorage.setItem("userId", data.id);
      localStorage.setItem("company", data.spCompanyId ? data.spCompanyId : data.company);

      this.getUserAndCompanyInformation()
      
      return true
    }
    return false
  }

  getUserAndCompanyInformation(){
    this.apiValidatorService.getUserInformation().subscribe( response => {

      this.apiValidatorService.getCompanyInformation(response.user.companyMongoId).subscribe( (response) => {
    
        localStorage.setItem('companyUseRoutes', response.company.useRoutes);
        localStorage.setItem('companyUseBinPacking', response.company.useBinPacking);
        localStorage.setItem('companyUseKmeans', response.company.useKmeans);
        localStorage.setItem('companyUseLastMile', response.company.useLastMile);
        localStorage.setItem('companyUseAutomaticOperation', response.company.useAutomaticOperation);
        localStorage.setItem('companyDefaultLanguage', response.company.defaultLanguage);
        localStorage.setItem('companyTraceability', response.company.traceabilityType ? response.company.traceabilityType : 'order' );
        localStorage.setItem('companyRouteOptimizer', response.company.routeOptimizer ? response.company.routeOptimizer : false );

        this.setUseRouteOptimizerSubject(response.company.routeOptimizer ? response.company.routeOptimizer : false);

        console.log(response.company.routeOptimizer);
        
        this.translate.setDefaultLang(response.company.defaultLanguage);
        this.translate.use(response.company.defaultLanguage);
        localStorage.setItem('lang', response.company.defaultLanguage);
        
      });

    });
  }

  logout() {
    this.user = null;
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem('home_data');
    localStorage.removeItem('updated_home_data');
    this._firebaseAuth.signOut();
    this.router.navigate(["/pages/login"]);
  }

  isAuthenticated() {
    return localStorage.getItem("token") !== null;
  }

  getToken() {
    return localStorage.getItem("token");
  }

  getIdRole(){
    let idRole = '';
    let token = localStorage.getItem('token');
    let data = JSON.parse(atob(token.split('.')[1]))

    idRole = data.idMongoRole;

    return idRole;
  }

  getCompanyId(){
    let companyId = '';
    let token = localStorage.getItem('token');
    let data = JSON.parse(atob(token.split('.')[1]))

    companyId = data.spCompanyId;

    return companyId;
  }

}
